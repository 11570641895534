import React from "react";
import { FullWidth } from "../components/fullWidth/FullWidth";
import { Layout } from "../components/layout/Layout";
import { Teammate } from "../components/team/Teammate";
import { Hr } from "../components/hr/Hr";
import "./team.scss";
import { StaticImage } from "gatsby-plugin-image";

const teammates = [
  {
    image: (
      <StaticImage
        src="../images/Sandra_photo.png"
        alt="Headshot of Sandra Amolo"
      />
    ),
    title: "Sandra Amolo, organization development",
    content: (
      <p>
        Sandra has over 10 years of experience in the nonprofit sector. She has
        helped boards develop strategy and vision focused on organization and
        community culture, liberation management coaching, and adapting tools
        for racial equity. Sandra is the Capacity Building Lead for Rooted in
        Vibrant Communities (RVC). She immigrated to the U.S. from Nairobi,
        Kenya as a teenager and speaks Kiswahili, English, and Spanish.
      </p>
    ),
  },
  {
    image: (
      <StaticImage
        src="../images/Betsey_photo.png"
        alt="Headshot of Betsey Archambault"
      />
    ),
    title: "Betsey Archambault, PhD, fundraising",
    content: (
      <p>
        Betsey is the founder of Copper Consulting and has worked for 20 years
        with nonprofits nationally and internationally, including Nepal, India,
        China, and Chad. As a two-time founding executive director and two-time
        development director, Betsey provides organizational development, board
        governance, and fundraising assistance to local community-based
        organizations.
      </p>
    ),
  },
  {
    image: (
      <StaticImage src="../images/Jing_photo.png" alt="Headshot of Jing Fong" />
    ),
    title: "Jing Fong, communications",
    content: (
      <p>
        Jing has 35 years experience in strategic communications, writing, and
        editing. She loves working with organizations to create strong funding
        proposals. She brings experience working with elected officials,
        nonprofit leaders, teachers, and students on communications, policy, and
        social justice.
      </p>
    ),
  },
  {
    image: (
      <StaticImage
        src="../images/Roxanne_photo_2.png"
        alt="Headshot of Roxanne Hood Lyons"
      />
    ),
    title: "Roxanne Hood Lyons, leadership coaching, strategic planning",
    content: (
      <p>
        Roxanne is proud to lead the exceptional RHL Consulting team. With over
        40 years of experience in enhancing outcomes for children—as the founder
        and executive director of a place-based nonprofit and a public-school
        educator—she brings a wealth of knowledge to her role. Roxanne enjoys
        working alongside community leaders as they reimagine systems and drive
        meaningful change.
      </p>
    ),
  },
  {
    image: (
      <StaticImage src="../images/Keats_photo.png" alt="Headshot of Keats" />
    ),
    title: "Keats Hoonan, graphic design, web design, digital marketing",
    content: (
      <p>
        Keats is a web and graphic designer with 9 years of experience. Since
        2021, he has been a consultant at RHL, focusing on design solutions for
        nonprofits. He creates user-friendly websites, original graphics, and
        enhances digital marketing efforts, including social media, content
        creation, and newsletters. Keats has helped Washington nonprofits
        achieve their goals with impactful design and marketing.
      </p>
    ),
  },
  {
    image: (
      <StaticImage src="../images/Shawn_photo.jpeg" alt="Headshot of Shawn" />
    ),
    title: "Shawn Koyano, organization development and coaching",
    content: (
      <p>
        Shawn Koyano has 25 years of experience working in and with non-profit
        and community-based organizations both large and small. She has directly
        supported and provided coaching for executive directors, directors and
        manager level staff. With a passion for supporting others and an
        understanding of non-profit management, Shawn is dedicated to helping
        individuals and organizations make a meaningful impact in their
        communities. She leads with compassion and understanding, working to
        bring out the innate wisdom that lives within each individual.
      </p>
    ),
  },
  {
    image: (
      <StaticImage src="../images/Lisa_photo.png" alt="Headshot of Lisa" />
    ),
    title: "Lisa Lau, administrative assistant",
    content: (
      <p>
        Lisa has been the administrative assistant for RHL since 2022 where her
        organizational skills led to the development of efficient tracking
        systems to support the team. She has a knack for systems thinking with
        skills in bookkeeping and customer service.
      </p>
    ),
  },
  {
    image: (
      <StaticImage src="../images/Sammy_photo.jpg" alt="Headshot of Sammy" />
    ),
    title: "Sammy (Samantha) Suh, project management",
    content: (
      <p>
        Sammy comes from an education background and started her career teaching
        in Chicago Public Schools. She transitioned to working with nonprofits
        in 2019 and has experience in project management, communications
        support, facilitation, coaching, and organizational development. In all
        of her work she focuses on relationship building and centering BIPOC
        voices. She is proficient in Korean.
      </p>
    ),
  },
];

const Team = () => {
  return (
    <Layout>
      <FullWidth>
        <div className="team--root">
          <div className="team--tagline">
            <div>
              RHL Team lead by value of community, generosity, and integrity.
            </div>
          </div>
          <Hr />
          <div className="team--list">
            {teammates.map((t) => (
              <Teammate key={t.title} {...t} />
            ))}
          </div>
          <Hr />
          <div className="team--quote inner-column">
            <quote>
              RHL's team centers race and social justice in all of our
              capacity-building work.
            </quote>
          </div>
          <Hr />
          <div className="team--commitment inner-column">
            <h2>
              Our commitment
              <br />
              to equity &amp; social justice
            </h2>
            <p>
              RHL consultants are immigrants, parents, foster parents, survivors
              of intimate partner violence, survivors of the criminal legal
              system and believers in change.
            </p>
            <p>
              Relationships are at the center of our work at RHL Consulting.
              Together, we vision a just world where all children thrive. To
              advance the collective move toward social justice, we:
            </p>
            <ul className="team--tactics">
              <li>
                Listen deeply to understand our client's cultural practices,
                values, and ways of being, and respectfully incorporate this
                knowledge into our work.
              </li>
              <li>
                Center the lead of BIPOC communities. We believe that community
                leaders hold the wisdom and vision to determine the best ways to
                improve outcomes for their families and neighbors.
              </li>
              <li>
                Together with grassroots organizations we co-create tools and
                design sustainable systems that address the root issues they
                experience.
              </li>
              <li>
                Challenge racist systems by advocating alongside our clients
                within government structures.
              </li>
              <li>
                Broker partnerships to co-create strong and sustainable
                organizations and alliances.
              </li>
              <li>
                Listen, reflect, and hold one another accountable to deepen our
                anti-racist skillset while striving for deeper understanding,
                peace, and collective liberation.
              </li>
            </ul>
          </div>
        </div>
      </FullWidth>
    </Layout>
  );
};

export default Team;
